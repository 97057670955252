import React, { useState, createContext, useContext } from "react";
import ReactDOM from "react-dom";
import reportWebVitals from "./reportWebVitals";
import { ThemeProvider } from "@pai-ui/core/theme/theme-provider";
// import axios from "axios";
import { ConfigProvider as PaiUiConfigProvider } from "@pai-ui/core/config/config-provider";
import { WindowConfigProvider } from "./context/WindowContext";
import { ThemeType } from "@pai-ui/core";
// import { ThemeType } from '@pai-ui/core/theme/types';
import WaveLoader from "@pai/assets/generic-components/wave-loader/WaveLoader";

const AppContext = createContext();
export const getAppContext = () => useContext(AppContext);

const App = React.lazy(() => import("./App.js"));

export const AppProvider = () => {
  const [contextData, updateContextData] = useState({});

  // const [login, setLogin] = React.useState(false);
  const [criticalFontsLoaded, setCriticalFontsLoaded] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const setContextData = (data) => {
    updateContextData({ ...contextData, ...data });
  };

  React.useEffect(() => {
    const fonts = {
      critical: [
        {
          family: "SF Display",
          weight: 200,
          src: "SF-UI-Display-Light.otf",
        },
        {
          family: "SF Display",
          weight: 400,
          src: "SF-UI-Display-Regular.otf",
        },
        {
          family: "SF Text",
          weight: 400,
          src: "SF-UI-Text-Regular.otf",
        },
        {
          family: "SF Text",
          weight: 200,
          src: "SF-UI-Text-Light.otf",
        },
      ],
      notCritical: [
        {
          family: "SF Display",
          weight: 700,
          src: "SF-UI-Display-Bold.otf",
        },
        {
          family: "SF Display",
          weight: 600,
          src: "SF-UI-Display-Semibold.otf",
        },
        {
          family: "SF Text",
          weight: 700,
          src: "SF-UI-Text-Bold.otf",
        },
        {
          family: "SF Text",
          weight: 700,
          style: "italic",
          src: "SF-UI-Text-BoldItalic.otf",
        },
        {
          family: "SF Text",
          weight: 200,
          style: "italic",
          src: "SF-UI-Text-LightItalic.otf",
        },
        {
          family: "SF Text",
          weight: 600,
          src: "SF-UI-Text-Semibold.otf",
        },
        {
          family: "SF Text",
          weight: 600,
          style: "italic",
          src: "SF-UI-Text-SemiboldItalic.otf",
        },
      ],
    };

    const loadAndAddFont = async (fontConfig) => {
      try {
        const font = new FontFace(
          fontConfig.family,
          `url(${process.env.REACT_APP_CLOUDFRONT_CDN}/fonts/${fontConfig.src})`,
          {
            weight: fontConfig.weight,
            style: fontConfig.style,
          }
        );

        await font.load();
        document.fonts.add(font);
      } catch (err) {
        console.error("ERROR_LOADING_FONTS", err, fontConfig);
      }
    };

    Promise.all(fonts.critical.map(loadAndAddFont)).then(() => {
      setCriticalFontsLoaded(true);

      const timeToWaitUntilAllCriticalAssetsAreLoaded = 500;

      setTimeout(() => {
        fonts.notCritical.forEach(loadAndAddFont);
      }, timeToWaitUntilAllCriticalAssetsAreLoaded);
    });
  }, []);

  React.useEffect(() => {
    if (criticalFontsLoaded) {
      setTimeout(() => {
        setLoading(true);
      }, 3000);
    }
  }, [criticalFontsLoaded]);

  return (
    <>
      <PaiUiConfigProvider
        type={ThemeType.dark}
        visualization={{
          resizing: {
            eventName: "customResize",
            debounceMs: 500,
          },
        }}
        // http={() => ({
        //   commonRequestConfig: {
        //     url: "/viz/GetData",
        //     baseUrl: process.env.REACT_APP_MICROSERVICE_HOST,
        //     headers: {
        //       "x-api-key": process.env.REACT_APP_API_KEY,
        //       Authorization: "Bearer " + authToken,
        //     },
        //   },
        // })}
        // access={{
        //   token:
        //     "eyJhbGciOiJIUzI1NiJ9.eyJzZWNyZXQiOiJkcGp4UXc3V2RXQXl4RnhQTUlPT0w3Ym9WbHg2dXg1Um0iLCJleHBpcmF0aW9uRGF0ZSI6IjIwOTktMTItMDZUMDU6MDA6MTEuNzE1WiJ9.jM_ah9uM95peux9xHy13ZLWHYPif3yXYDME1VJJVZD8", // Required
        // }}
        /** #TODO : Below prop values are hardcoded for now. change these to env files later */
        access={{
          secret: '6fCB49F95Cbb89CaC5F52D518c88df17',
          requestConfig: {
            payload: {
              client_name: 'panera',
              client_env: 'prod',
              product_name: 'p.ai'
            },
            headers: {
              'x-api-key': 'Eyh7cwF8hV2T5Pzq',
              "Content-Type": "application/json",
            }
          }
        }}
      >
        <ThemeProvider componentsStyles={
          {
            visualization: {
              root: {
                zoom: '1.1111111'
              }
            }
          }
        }>
          <React.Suspense fallback={<WaveLoader />}>
            <AppContext.Provider value={{ contextData, setContextData }}>
              <WindowConfigProvider>
                <App
                  criticalFontsLoaded={criticalFontsLoaded}
                  loading={loading}
                />
              </WindowConfigProvider>
            </AppContext.Provider>
          </React.Suspense>
        </ThemeProvider>
      </PaiUiConfigProvider>
    </>
  );
};

// eslint-disable-next-line react/no-deprecated
ReactDOM.render(<AppProvider />, document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
